import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import Button from 'components/Button'
import Text from 'components/Text'
import Heading from 'components/Heading'

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  flex: 1 1;
`

const NotFound = () => {
  return (
    <StyledNotFound>
      <Heading scale="xxl">404</Heading>
      <Text mb="16px">Oops, page not found.</Text>
      <Link href="/" passHref>
        <Button as="a">Back Home</Button>
      </Link>
    </StyledNotFound>
  )
}

export default NotFound
